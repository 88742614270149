$(function () {

    $('.carousel').carousel({
        pause: true,
        interval: 8000
    });

    if ($('.vote-popover-content').length) {
        var tour = new Tour({
            name: 'vote-tour',
            backdrop: true,
            backdropContainer: 'body',
            backdropPadding: '0 2 2 0',
            keyboard: false,
            orphan: true,
            storage: false,
            template: "<div class='popover tour tour-popover'>\
                        <div class='arrow'></div>\
                        <div class='popover-title-container'>\
                            <h2 class='popover-title'></h2>\
                            <div class='popover-close' data-role='end'>&times;</div>\
                        </div>\
                        <div class='popover-content'></div>\
                    </div>"
        });

        tour.addSteps([
            {
                title: 'STEMMEN',
                content: function() {
                    return $('.vote-popover-content').html();
                }
            }]);
        tour.init();
        tour.start(true);
    }

    var readMores = $('.teacher-read-more');
    readMores.on('click', function(event) {
        event.preventDefault();
        var infoBlock =  $(this).parent().parent().find('.course-teacher-info');
        var readMore = this;
        infoBlock.slideToggle({
            'duration' : 500,
            'progress' : function() {
                $('body').scrollTop($(readMore).offset().top - 65);
            },
            'complete' : function() {
                $(this).parent().find('.teacher-read-more').each(function(){
                    $(this).toggle();
                });
            }
        });
    });

    var subscribe = $('.js-subscribe');
    subscribe.click(function (e) {
        e.preventDefault();

        $.ajax({
            url: '/subscribe',
            type: 'POST',
            data: {
                '_token': $('meta[name=_token]').attr('content'),
                'email': $('#js-email').val(),
                'role': $('#js-role').val(),
                'locale': $('#js-locale').val()
            },
            success: function () {
                $('#js-email-alert').show();
            }
        });
    });

    var contact = $('.js-contact');
    contact.click(function (e) {
        e.preventDefault();

        $.ajax({
            url: '/contact',
            type: 'POST',
            data: {
                '_token': $('meta[name=_token]').attr('content'),
                'name': $('#js-contact-name').val(),
                'email': $('#js-contact-email').val(),
                'message': $('#js-contact-message').val()
            },
            success: function () {
                $('#js-contact-alert').show();
            }
        });

    });

});